
import React from "react";
import { BrowserRouter as Router, Routes, Route, Private,Outlet} from 'react-router-dom'
import { Home, About, Products, SingleProduct, Cart, Error, Checkout, PrivateRoute, Contact, Specials, Profile } from './pages'

import styled from 'styled-components'
import Footer from "./components/Footer";
import { Navbar, Sidebar } from "./components";



function App() {
  return (
    <Router>
      <Navbar/>
      <Sidebar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/cart" element={<Cart/>}/>
          <Route path="/products" element={<Products/>}/>
          <Route path="/products/:id" element={<SingleProduct/>}/>
          <Route path="/checkout" element={<PrivateRoute><Checkout/></PrivateRoute>}/>
          <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/specials/:title" element={<Specials />}/>
          <Route path="*" element={<Error/>}/>         
        </Routes>   
        <Footer/>     
    </Router>
   
  );
}

export default App;

import {
  ADD_TO_CART,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  REMOVE_CART_ITEM,
  TOGGLE_CART_ITEM_AMOUNT,
  CHECK_CUSTOMER,
  GET_CUSTOMER_ERROR,
  PROCESS_ORDER,
} from '../actions'




const cart_reducer = (state, action) => {
    
    if(action.type === ADD_TO_CART){
        const {id,amount,product} = action.payload;
        const tempItem = state.cart.find((i)=> i.id === id)
        if(tempItem){
            const tempCart = state.cart.map((cartItem)=>{
                if(cartItem.id === id){
                    let newAmount = cartItem.amount + amount;
                    if(newAmount > cartItem.max){
                        newAmount = cartItem.max
                    }
                    return {...cartItem,amount: newAmount}
                }
                else{
                    return cartItem
                }

            })
            return {...state,cart:tempCart}
        }
        else{
            const newItem = {
                id:id,
                name: product.item_name,
                amount,
                image:product.item_image,
                color:product.colors.color,
                price:product.item_cost,
                max:product.quantity_available,
            }
            return {...state,cart:[...state.cart,newItem]}
        }
    }

    if(action.type === REMOVE_CART_ITEM){
        const tempCart = state.cart.filter((item)=> item.id !== action.payload)
        return {...state, cart:tempCart}
    }

    if(action.type === CLEAR_CART){
        return {...state, cart:[]}
    }

    if(action.type === PROCESS_ORDER){
        return {...state, prossesed: true}
    }
    // check if customer exsist
    if(action.type === CHECK_CUSTOMER){
       
        const customerstatus = action.payload.customer_sub
        const customerId = action.payload.customer_id
        console.log("customerstatus", customerstatus);

        if( typeof customerstatus !== null){
            // myVar is undefined or null
            return {...state, customer: true, customer_id:customerId}
        }
        else {
            return {...state, customer: false}
        }
        
    }

    if(action.type === GET_CUSTOMER_ERROR){
        return {...state,customer_error:'No customer exsist'}
    }
    if(action.type === TOGGLE_CART_ITEM_AMOUNT){
        const{id,value} = action.payload
        const temCart = state.cart.map((item)=>{
            if(item.id === id){
                if(value === 'inc'){
                    let newAmount = item.amount + 1
                    if(newAmount > item.max){
                        newAmount = item.max
                    }
                    return {...item,amount:newAmount}
                }
                if(value === 'dec'){
                     let newAmount = item.amount - 1
                    if(newAmount < 1){
                        newAmount = 1
                    }
                    return {...item,amount:newAmount}

                }
            }
            else{
                return item
            }
        })

        return {...state,cart:temCart}
    }
    if(action.type === COUNT_CART_TOTALS){
        const {total_items,total_amount} = state.cart.reduce((total,cartItem) => {
            const {amount,price} = cartItem
            total.total_items += amount;
            total.total_amount += price * amount

            return total
        },{
            total_items:0,total_amount:0
        })
        return {...state,total_items,total_amount}
    }

    return state
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default cart_reducer
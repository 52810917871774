import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components'



const Profile = () => {
    const { user } = useAuth0();
    const {name,picture,email} = user;

  return (

    <main>
      <div className='header'><h2>User Profile</h2></div>
      <div className='profile-container'>
        <div className='profile-section'>
        <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
          <h2>{name}</h2>
          <p>{email}</p>

        
        </div>

      </div>


    </main>
    
    
    
  )
}

const Wrapper = styled.section`
  display: grid;
  gap: 4rem;
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  }
  p {
    line-height: 2;
    max-width: 45em;
    margin: 0 auto;
    margin-top: 2rem;
    color: var(--clr-grey-5);
  }
  .title {
    text-align: left;
  }
  .underline {
    margin-left: 0;
  }
  .header {
    text-align: center;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  .profile-container {
    display: grid;
    column-gap: 30px;
    grid-template-columns: auto auto auto;
    padding: 10px;
  }

  .profile-section {
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto auto ;
  }

  input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing : border-box;
  }
`


export default Profile

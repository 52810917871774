import React, { useEffect, useContext, useReducer } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import reducer from '../reducers/cart_reducer'
import axios from 'axios'
import { create_order_url as url } from '../utils/constants'


import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  TOGGLE_CART_ITEM_AMOUNT,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  CHECK_CUSTOMER,
  GET_CUSTOMER_ERROR,
  PROCESS_ORDER,
} from '../actions'


const getLocalStorage = ()=>{
    let cart = localStorage.getItem('cart');
    if(cart){
        return JSON.parse(localStorage.getItem('cart'))
    }
    else{
        return []
    } 
}



const initialState = {
  cart:getLocalStorage(),
  total_items:0,
  total_amount:0,
  shipping_fee:534,
  customer:false,
  customer_error:"",
  customer_id:"",
  processed:false,
}

const CartContext = React.createContext()



export const CartProvider = ({ children }) => {
    const {user} = useAuth0();
    const [state,dispatch] = useReducer(reducer,initialState);
    const {getAccessTokenSilently } = useAuth0();

    // add to cart

    const addToCart = (id,amount,product) =>{
        dispatch({type:ADD_TO_CART,payload:{id,amount,product}})
    }

    // remove items
    const removeItem = (id)=>{
      dispatch({type:REMOVE_CART_ITEM,payload:id})

    }
    // toggle amount 
    const toggleAmount = (id,value)=>{
      dispatch({type:TOGGLE_CART_ITEM_AMOUNT,payload:{id,value}})

    }

    const checkCustomer = async (url)=>{

      
    
      try {
        const response = await axios.get(url)
        const customer_response = response.data
        const customer_id = customer_response.id
        const customer_sub = customer_response.sub        
                    
        dispatch({type: CHECK_CUSTOMER, payload:{customer_id,customer_sub}})
    } catch (error) {
        dispatch({type: GET_CUSTOMER_ERROR})
    }
      
    }

    const createUser = async (createurl)=>{

     
         
      try{

        const token = await getAccessTokenSilently()
        const newcustomer = {"username": user.name, "email": user.email, "sub": user.sub}  
        const response = await axios.post(createurl,newcustomer,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
         });
        const customer = response.data
      }catch(error){
        console.log(error);
        console.log('no user found');
      }
    }

    // clear cart
    const clearCart = ()=>{
        dispatch({type:CLEAR_CART})
    }

    const processOrder = ()=>{
      dispatch({type:PROCESS_ORDER})
    }

    useEffect(()=>{
        localStorage.setItem('cart',JSON.stringify(state.cart))
        dispatch({type:COUNT_CART_TOTALS})
    },[state.cart])

   const createOrder = async() => {

    const token = await getAccessTokenSilently()

    console.log(token);
     
    const lineItems = state.cart.map(lineitem => {
      const items = {};
      items.item = lineitem.id;
      items.order_quantity = lineitem.amount;
      
      return items

     
            
    })

    const order = {"customer": state.customer_id,
                    "status": true,
                     "lineitems": lineItems}

    console.log(order);
   

     
    
     axios.post(url,order,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
     }).then(response => {
        
       console.log(response);
       
      


       
       
     })

     
   }
    
  return (    
    <CartContext.Provider value={{...state,addToCart,removeItem,toggleAmount,clearCart,createOrder,checkCustomer,createUser,processOrder}}>{children}</CartContext.Provider>
  )

}

export const useCartContext = () => {
  return useContext(CartContext)
}
import React from 'react'
import styled from 'styled-components'
import { specials } from '../utils/constants'
import { Link } from 'react-router-dom'
import { useFilterContext } from '../context/filter_context'

const Specials = () => {
  const {getSpecial} = useFilterContext
    return (
        <Wrapper>
            <div className='section-center'>
                <article className='header'>
                    <h3>Special Packages <br/>
                     for those special occasions 
                    </h3>
                    <p>
                        Choose from out selection of Holiday and Celebration packages. Items are specially priced when bundled ensuring 
                        that you get the most value...
                    </p>
                </article>
                <div className='services-center' >
                    {specials.map((special)=>{
                        const {id,icon,title,text} = special;
                        return <article key={id} className='service'>
                            {/* <span className='icon'></span> */}
                            
                              <img className = 'icon-image' src={icon}></img>
                            
                            <h4>{title}</h4>
                            <p>{text}</p>
                            <Link to={`/specials/${title}`} className='btn hero-btn'>Click here</Link>
                        </article>
                    })}

                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`
  h3,
  h4 {
    color: white;
  }
  padding: 5rem 0;

  background: DodgerBlue;

  .header h3 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 0;
    line-height: 1.8;
    color: white;
  }

  .icon-image {
    width:10rem;
    height: 10rem;
    object-fit: cover;
  }
  .services-center {
    margin-top: 4rem;
    display: grid;
    gap: 2.5rem;
  }
  .service {
    background: #D4AA00;
    text-align: center;
    padding: 2.5rem 2rem;
    border-radius: var(--radius);
    p {
      color: white;
    }
  }
  span {
    width: 4rem;
    height: 4rem;
    display: grid;
    margin: 0 auto;
    place-items: center;
    margin-bottom: 1rem;
    border-radius: 50%;
    background: var(--clr-primary-10);
    color: var(--clr-primary-1);
    svg {
      font-size: 2rem;
    }
  }
  @media (min-width: 992px) {
    .header {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 576px) {
    .services-center {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
  @media (min-width: 1280px) {
    padding: 0;
    .section-center {
      transform: translateY(5rem);
    }
  }
`
export default Specials
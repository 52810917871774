import React from 'react'
import { FaShoppingBasket, FaUserMinus, FaUserPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useProductsContext } from '../context/products_context'
import { useCartContext } from '../context/cart_context'
import { useUserContext } from '../context/user_context'

const CartButtons = () => {
    const {closeSidebar} = useProductsContext();
    const {total_items} = useCartContext()
    const {loginWithRedirect,myUser,logout} = useUserContext();
    return (
        <Wrapper className='cart-btn-wrapper'>
            <Link to="/cart" className='cart-btn' onClick={closeSidebar}>
                Cart
                <span className='cart-container'>
                    <FaShoppingBasket style={{ color: "white" }}/>
                    <span className='cart-value'>{total_items}</span>
                </span>
            </Link>
            {myUser ?  <button type='button' className='auth-btn dropdown'>
                Logout <FaUserMinus style={{ color: "white" }} onClick={()=>logout({returnTo:window.location.origin})}/>
            </button> : <button type='button' className='auth-btn'>
                Login <FaUserPlus style={{ color: "white" }} onClick={loginWithRedirect}/>
            </button>}
            
           
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 225px;

  .cart-btn {
    color: var(--clr-grey-1);
    font-size: 1.5rem;
    letter-spacing: var(--spacing);
    color: white;
    display: flex;

    align-items: center;
  }
  .dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}



  .cart-container {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      height: 1.6rem;
      margin-left: 5px;
    }
  }
  .cart-value {
    position: absolute;
    top: -10px;
    right: -16px;
    background: var(--clr-primary-5);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.75rem;
    color: var(--clr-white);
    padding: 12px;
  }
  .auth-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    letter-spacing: var(--spacing);
    svg {
      margin-left: 5px;
    }
  }
`

export default CartButtons
import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useProductsContext } from '../context/products_context'
import { single_product_url as url } from '../utils/constants'
import { formatPrice } from '../utils/helpers'
import {
  Loading,
  Error,
  ProductImages,
  AddToCart,
  Stars,
  PageHero,
} from '../components'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const SingleProductPage = () => {
  console.log(useParams);
    const {id} = useParams();    
    const navigate = useNavigate()
    const {single_product_loading:loading,single_product_error:error,single_product:product,fetchSingleProduct}= useProductsContext();
    console.log(product);
    

    useEffect(()=>{
      
        fetchSingleProduct(`${url}${id}`)        
    },[id])

    useEffect(()=>{
        if(error){
          setTimeout(() => {
            navigate('/')
          }, 3000);
        }
    },[error])
    if(loading){
      return <Loading/>
    }
    if(error){
      return <Error/>
    }
    const {item_name,item_cost,item_description,quantity_available,stars,reviews,ages,id:sku,images} = product

    
    
    return (
        <Wrapper>
            <PageHero title={item_name} product/>
            <div className='section section-center page'>
              <Link to='/products' className='btn'>back to products</Link>
              <div className='product-center'>
                { images.length > 0 ? <ProductImages images={images}/> : 'no images' }
                
                <section className='content'>
                  <h2>{item_name}</h2>
                  <Stars stars={stars} reviews={reviews}/>
                  <h5 className='price'>{formatPrice(item_cost)}</h5>
                  <p className='description'>{item_description}</p>
                  <p className='info'>
                      <span>Available : </span>
                      {quantity_available > 0 ? 'In Stock': 'out of stock'}
                  </p>
                  <p className='info'>
                      <span>ID : </span>
                      {sku}
                      
                  </p>
                  <p className='info'>
                      <span>Age Group : </span>
                      {ages === undefined || ages.age_group}                      
                  </p>
                  <hr/>
                  { quantity_available > 0 && <AddToCart product={product}/>}
                </section>
              </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.main`
  .product-center {
    display: grid;
    gap: 4rem;
    margin-top: 2rem;
  }
  .price {
    color: var(--clr-primary-5);
  }
  .desc {
    line-height: 2;
    max-width: 45em;
  }
  .info {
    text-transform: capitalize;
    width: 300px;
    display: grid;
    grid-template-columns: 125px 1fr;
    span {
      font-weight: 700;
    }
  }

  @media (min-width: 992px) {
    .product-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
    .price {
      font-size: 1.25rem;
    }
  }
`

export default SingleProductPage

import {
  LOAD_PRODUCTS,
  SET_GRIDVIEW,
  SET_LISTVIEW,
  UPDATE_SORT,
  SORT_PRODUCTS,
  UPDATE_FILTERS,
  FILTER_PRODUCTS,  
  CLEAR_FILTERS,
  GET_SPECIAL,
} from '../actions'
import { FilterProvider } from '../context/filter_context';
import products_reducer from './products_reducer';


const filter_reducer = (state,action) => {
    if(action.type === LOAD_PRODUCTS){
      let maxPrice = action.payload.map((p) => p.item_cost);
      maxPrice = Math.max(...maxPrice)
        return {...state,all_products:[...action.payload],filtered_products:[...action.payload],filters:{...state.filters,max_price:maxPrice,price:maxPrice}}
    }
    if(action.type === SET_GRIDVIEW){
      return {...state,grid_view:true}
    }
     if(action.type === SET_LISTVIEW){
      return {...state,grid_view:false}
    }
    if(action.type === UPDATE_SORT){
      return {...state, sort:action.payload}
    }
    if(action.type === SORT_PRODUCTS){
      const {sort,filtered_products} = state;
      let tempProducts = [...filtered_products];
      if(sort === 'price-lowest'){
          tempProducts = tempProducts.sort((a,b) => a.item_cost - b.item_cost)
      }
       if(sort === 'price-highest'){
         tempProducts = tempProducts.sort((a,b)=> b.item_cost - a.item_cost)        
      }
       if(sort === 'name-a'){
        tempProducts = tempProducts.sort((a,b)=> {
            return a.item_name.localeCompare(b.item_name)
        })
      }
      
       if(sort === 'name-z'){
         tempProducts = tempProducts.sort((a,b)=> {
            return b.item_name.localeCompare(a.item_name)
      })
    }
     return {...state,filtered_products:tempProducts}
    }
    
    if(action.type === UPDATE_FILTERS){
      const {name,value} = action.payload
      return {...state,filters:{...state.filters,[name]:value}}
    }

    if(action.type === GET_SPECIAL){
      const {all_products} = state
      const choice = action.payload
      
      let tempProducts = [...all_products]

      if(choice === 'Christmas'){
        tempProducts = tempProducts.filter((product)=> product.special.special_name === choice)
      }

      if(choice === 'Independence'){
        tempProducts = tempProducts.filter((product)=> product.special.special_name === choice)
      }

      if(choice === 'Birthday'){
        tempProducts = tempProducts.filter((product)=> product.special.special_name === choice)
      }

      if(choice !== 'Birthday' && choice !=='Independence' && choice !== 'Christmas'){
        tempProducts = ""
      }

      return {...state,filtered_specials:tempProducts}
    }
    if(action.type === FILTER_PRODUCTS){
      const {all_products} = state
      const {text,category,agegroup,price,color} = state.filters


      let tempProducts = [...all_products]

      //text filter
      if(text){
        
        tempProducts = tempProducts.filter((product)=>{
          return product.item_name.toLowerCase().startsWith(text)
        })
      } 
      ////category filter

      if(category !=='all'){
        tempProducts = tempProducts.filter((product) => product.types.item_type === category)

      }

      

      ///Colour filter 

      if( color !=='all'){
        tempProducts = tempProducts.filter((product) => product.colors.colour_name === color)
      }
      ///Age group  filter
      if(agegroup !=='all'){
        tempProducts = tempProducts.filter((product) => product.ages.age_group === agegroup)

      }

      ///Price filter 
      tempProducts = tempProducts.filter((product) => product.item_cost <= price)


      


      return {...state,filtered_products:tempProducts}
    }
    if(action.type === CLEAR_FILTERS){
      return {...state, filters:{...state.filters,
      text:'',
      agegroup:'all',
      category:'all',
      color:'all',
      price:state.filters.max_price,   
      shipping:false,
    }}
    }

     
    
    throw new Error(`No Matching "${action.type}" - action type`)
}

export default filter_reducer
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useUserContext } from '../context/user_context';
// import { useAuth0 } from '@auth0/auth0-react';

const PrivateRoute = ({children}) => {
    const {myUser} = useUserContext();
    
        return myUser ? children : <Navigate to="/"></Navigate>;
    
};
export default PrivateRoute;
import React from 'react'
import styled from 'styled-components'
import { PageHero } from '../components'
// extra imports
import { useCartContext } from '../context/cart_context'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import CartSummary from '../components/CartSummary'
import { useAuth0 } from '@auth0/auth0-react'
import { check_customer_url as customerurl, create_customer_url } from '../utils/constants'
import { create_customer_url as createurl } from '../utils/constants'

const CheckoutPage = () => {
    const {cart,createOrder,checkCustomer, customer, createUser, clearCart} = useCartContext();
    const {user} = useAuth0();
    const {getAccessTokenSilently } = useAuth0();
    const [payNow, setPayNow] = useState(false);

  


    useEffect(()=>{      
        checkCustomer(`${customerurl}${user.sub}`)
        console.log(customer);
        if(customer === false){
            createUser(`${create_customer_url}`)

            console.log(customer);
        }
    },[user])


    return (
        <main>
            <PageHero title="checkout"/>
            <Wrapper className='page'>
                <form onSubmit={(e)=>e.preventDefault()}>
                    
                        <div className='btnContainer'>
                        <div className='form-control'>
                        <button className='btn' onClick={()=> setPayNow(false)}>Pay Later</button>
                        <button className='btn' onClick={()=> setPayNow(true)}>Pay Now</button>
                        </div>
                        
                    </div>                  

                </form>

                
                   {
                        payNow ?  <div>Coming soon</div>: <div className='btnContainer'>
                            <h4> Thank you {user.name}</h4>
                            
                            <h2>Pay Later</h2>
                        
                        {
                            
                            cart.map((item)=>{
                                return (
                                    
                                    <CartSummary key={item.id}{...item}/>
                                )
                                
                                
                            })
                        }

                        { cart.length < 1 || <button className='btn' onClick={()=> {createOrder(); clearCart();}}>Confirm</button>
}
                    
                </div>
                }

                


            </Wrapper>
        </main>
    )
}


const Wrapper = styled.div`
display: flex;
align-center: center;
justify-content: center;
.empty {
  text-align: center;
}
.btnContainer {
    width: fit-content;
    border: 2px solid black;
    padding: 30px;
    margin: 20px;
    display: block;
}
`
export default CheckoutPage
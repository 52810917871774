export const formatPrice = (number) => {
    const newNumber = Intl.NumberFormat('en-US', {
        style:'currency', currency: 'USD'
    }).format(number/100)
    return newNumber
}

export const getUniqueValues = (data,type) => {
  
    let unique = data.map((item)=> item[type])
    return ['all',...new Set(unique)]
}

export const getUniqueValuesTypes = (data,type) => {
  
    let unique = data.map((item)=> item.types[type])
    return ['all',...new Set(unique)]
}

export const getUniqueValuesAges = (data,type) => {
  
    let unique = data.map((item)=> item.ages[type])
    return ['all',...new Set(unique)]
}

export const getUniqueValuesColours = (data,type) =>{
    let unique = data.map((item)=> item.colors[type])
    return ['all',...new Set(unique)]
}
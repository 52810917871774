import React from 'react'
import christmas from '../assets/christmas.jpg'
import birthdays from '../assets/birthday.jpg'
import independence from '../assets/grenada.jpg'

export const links = [
  {
    id: 1,
    text: 'home',
    url: '/',
  },
  {
    id: 2,
    text: 'about',
    url: '/about',
  },
  {
    id: 3,
    text: 'products',
    url: '/products',
  },
  {
      id: 4,
      text: 'contact us',
      url: '/contact',
  },
  
]

export const specials = [
  {
    id: 1,
    icon: christmas,
    title: 'Christmas',
    text:
      'Find the perfect Christmas Gift package for your little one',
  },
  {
    id: 2,
    icon: birthdays,
    title: 'Birthdays',
    text:
      'Birthdays are a special time, celebrate with a special birthdy package',
  },
  {
    id: 3,
    icon: independence,
    title: 'Independence',
    text:
      'Also available: our independence themed package. ',
  },
]

// export const products_url = 'http://127.0.0.1:8000/api/item-list'

export const products_url = 'http://192.241.153.7/api/item-list'


// export const single_product_url = `http://127.0.0.1:8000/api/item-detail/`

export const single_product_url = `http://192.241.153.7:8000/api/item-detail/`

// export const create_order_url = 'http://127.0.0.1:8000/api/order-create/'

export const create_order_url = 'http://192.241.153.7/api/order-create/'

// export const check_customer_url = 'http://127.0.0.1:8000/api/check-customer/'

export const check_customer_url = 'http://192.241.153.7:8000/api/check-customer/'

// export const create_customer_url = 'http://127.0.0.1:8000/api/register-user/'

export const create_customer_url = 'http://192.241.153.7:8000/api/register-user/'

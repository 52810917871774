import React from 'react'
import { FeaturedProducts, Hero, Services, Contact, Specials, ContactComponent } from '../components'

const HomePage = () => {
    return (
        <main>
            <Hero/>
            <FeaturedProducts/>
            <Specials/>
            <ContactComponent/>

        </main>
    )
}

export default HomePage
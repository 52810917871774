import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ProductsProvider} from './context/products_context'
import ProductsPage from './pages/ProductsPage';
import { FilterProvider } from './context/filter_context';
import { CartProvider } from './context/cart_context';
import {Auth0Provider } from '@auth0/auth0-react'
import { UserProvider } from './context/user_context';

// dev-psmpdsyq.us.auth0.com

// 16K3MAigFv8tFTTwNLCsVdYh9ynhrGEg

ReactDOM.render(

  
  <Auth0Provider
    domain="dev-psmpdsyq.us.auth0.com"    
    clientId="16K3MAigFv8tFTTwNLCsVdYh9ynhrGEg"
    redirectUri={window.location.origin}
    cacheLocation='localstorage'
    audience="https://knitbabies/api"
    scope="read:messages create:order update:order"
    >

    <React.StrictMode>
      <UserProvider>
    <ProductsProvider>
      <FilterProvider>
        <CartProvider>
          <App />
        </CartProvider>        
      </FilterProvider>       
    </ProductsProvider>


      </UserProvider>
    
   
  </React.StrictMode>





  
  </Auth0Provider>,
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
